import React from 'react'
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import LayoutStatic from '../../../components/LayoutStatic';

const style = {
  button: {
    marginTop: '10px',
    marginRight: '10px',
    width: '45%'
  }
}

const IndexPage = () => {
  return (
    <LayoutStatic>
      <Helmet>
        <title>แบบประเมินพัฒนาการเด็ก - MorOnline</title>
      </Helmet>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6}>
          <div className="card w-100 mt-1">
            <img src="/img/CardWriting.jpg" className="card-img-top bootstrap-card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">ประเมินพัฒนาการรอบด้าน</h5>
              <p className="card-text">ลูกของเราพัฒนาการสมวัยหรือไม่? ร่วมประเมินกับผู้เชี่ยวชาญเฉพาะทาง นักกิจกรรมบำบัด ฟรี!!</p>
              <Link to="/menu/infant" className="btn btn-primary" style={style.button}>0-11 เดือน</Link>
              <Link to="/menu/child/01" className="btn btn-primary" style={style.button}>อายุ 1 ปี</Link>
              <Link to="/menu/child/02" className="btn btn-primary" style={style.button}>อายุ 2 ปี</Link>
              {/* <Link to="/menu/child/01" className="btn btn-primary" style={style.button}>อายุ 2 ปี</Link> */}
              <Link to="/menu/child/03" className="btn btn-primary" style={style.button}>อายุ 3 ปี</Link>
              <Link to="/menu/child/04" className="btn btn-primary" style={style.button}>อายุ 4 ปี</Link>
              <Link to="/menu/child/05" className="btn btn-primary" style={style.button}>อายุ 5 ปี</Link>
              <Link to="/menu/child/06" className="btn btn-primary" style={style.button}>อายุ 6 ปี</Link>
              <Link to="/menu/child/07" className="btn btn-primary" style={style.button}>อายุ 7 ปี</Link>
              <Link to="/menu/child/08" className="btn btn-primary" style={style.button}>อายุ 8 ปี</Link>
            </div>
          </div>
        </Grid>
      </Grid>
    </LayoutStatic>
  );
}

export default IndexPage

